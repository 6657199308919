// TransactionTimelineStyle.js - Styled components for the transaction timeline layout
import styled from 'styled-components';

// Main container for the transaction timeline, centers all child elements
export const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column; /* Align items vertically */
  align-items: center; /* Center all children horizontally */
  justify-content: flex-start; /* Align items to the top */
  padding: 20px 0; /* Space out content vertically */
  width: 90%; /* Restrict the container width for better centering */
  max-width: 1200px; /* Set a maximum width for readability */
  margin: 0 auto; /* Center the container within the viewport */
  gap: 20px; /* Add gap between elements */
`;

// Defines a row for each transaction with neumorphic design
export const TransactionRow = styled.div`
  display: flex;
  align-items: center; /* Vertically center content */
  justify-content: space-between; /* Distribute items evenly across the row */
  gap: 10px; /* Add space between timestamp, hash, and details */
  padding: 15px 25px; /* Padding inside each row for a balanced look */
  margin: 0 auto 20px; /* Center the row and add a bottom margin */
  width: 95%; /* Set the row width to fit within the container */
  max-width: 1300px; /* Prevent row from stretching too far */
  border-radius: 12px; /* Rounded corners for a softer look */
  // background: #1b1b1b; /* Dark background color to contrast the shadows */
  
  /* Neumorphic shadow effects */
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.6), /* Dark shadow for depth */
              -8px -8px 16px rgba(255, 255, 255, 0.05); /* Light shadow for raised effect */
`;


// Container for the hash link, controls layout and spacing
export const HashContainer = styled.div`
  display: flex;
  align-items: center; /* Vertically center the hash link */
  justify-content: flex-start; /* Align hash link to the left */
  padding: 0 10px; /* Slight padding for aesthetics */
  margin: 0;
  min-width: 180px; /* Minimum width to prevent shrinking */
  text-align: left; /* Align text within the container */
  flex: 0 1 auto; /* Prevents excessive stretching */
`;

// Link styling for the transaction hash
export const HashLink = styled.a`
  color: #ffffff; /* Set text color to white for contrast */
  text-decoration: underline; /* Underline to indicate it's clickable */
  &:hover {
    color: #8abdff; /* Optional: Change color on hover for better UX */
  }
`;

// Timestamp styling, positioned to the left with adequate padding
export const Timestamp = styled.div`
  flex: 0 0 auto; /* Prevent the timestamp from shrinking */
  text-align: left; /* Align timestamp text to the left */
  padding-right: 10px; /* Add padding for separation from other elements */
  font-size: 0.9em; /* Slightly smaller text size */
  color: #999; /* Light grey color for subtle appearance */
  min-width: 100px; /* Set a minimum width for consistency */
`;

// Connector line that visually connects transactions
export const ConnectorLine = styled.div`
  position: absolute;
  width: 2px; /* Thickness of the line */
  border-left: 2px dotted ${(props) => (props.type === 'incoming' ? '#32a852' : '#a83232')}; /* Color based on type */
  left: 50%; /* Center the line relative to the container */
  transform: translateX(-50%); /* Correct centering */
  top: 0;
  height: 100%; /* Extend the line to match container height */
  z-index: -1; /* Ensure line appears behind content */
`;
