// TransactionDetailsStyle.js - Styled components with pop-out and hover color effects
import styled, { css } from 'styled-components';

// Helper function to determine the initial neumorphic shadow style based on subtype
const getBorderStyle = (subtype) => {
  switch (subtype) {
    case 'Received':
      return 'box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.2), -8px -8px 16px rgba(50, 168, 82, 0.3);'; // Green shadow for received
    case 'Sent':
      return 'box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.2), -8px -8px 16px rgba(168, 50, 50, 0.3);'; // Red shadow for sent
  }
};

// Function to determine hover background color based on subtype
const getHoverBackgroundColor = (subtype) => {
  switch (subtype) {
    case 'Received':
      return 'background-color: rgba(50, 168, 82, 0.1);'; // Light green for received
    case 'Sent':
      return 'background-color: rgba(168, 50, 50, 0.1);'; // Light red for sent
    default:
      return 'background-color: rgba(255, 255, 255, 0.1);'; // Light neutral for other
  }
};

// Styled component for FlowItem with neumorphic design and pop-out hover effect
export const FlowItem = styled.div`
  position: relative;
  padding: 10px 20px; /* Adequate padding for content readability */
  color: #fff; /* White text color for contrast */
  width: 25vw; /* Adjust width based on the number of sub-transactions */
  border-radius: 12px; /* Rounded corners for neumorphic design */
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease; /* Smooth transition for pop-out effect */

  /* Initial neumorphic shadow effect */
  ${({ subtype }) => css`
    ${getBorderStyle(subtype)}
  `}

  /* Hover effect to simulate popping out with conditional background color */
  &:hover {
    transform: scale(1.05); /* Slightly scale up the item to create the pop-out effect */
    box-shadow: 12px 12px 24px rgba(0, 0, 0, 0.3), 
                -12px -12px 24px rgba(255, 255, 255, 0.15); /* Enhance shadow for lifted appearance */
    ${({ subtype }) => css`
      ${getHoverBackgroundColor(subtype)} /* Apply the light background color based on the subtype */
    `}
  }
`;
