const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001'; // Use environment variable to manage this URL

const fetchTransactions = async (address) => {
  console.log("fetchTransactions called with address:", address);
  try {
      const response = await fetch(`${BACKEND_URL}/transactions?address=${address}`);
      if (!response.ok) {
          throw new Error('Failed to fetch transactions');
      }
      const data = await response.json();
      console.log("fetchTransactions response:", data);
      return data.transactions || [];
  } catch (error) {
      console.error("Error fetching transactions from backend:", error);
      return [];  // Return an empty array or appropriate error handling
  }
};


export default fetchTransactions;