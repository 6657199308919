// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Replace 'Switch' with 'Routes'
import ChoiceScreen from './pages/ChoiceScreen';
import Portfolio from './pages/Portfolio';

const App = () => {
  return (
    <Router>
      <Routes> {/* Replace 'Switch' with 'Routes' */}
        <Route exact path="/" element={<ChoiceScreen />} /> {/* Update component prop to element */}
        <Route path="/portfolio/:address" element={<Portfolio />} /> {/* Update component prop to element */}
      </Routes>
    </Router>
  );
};

export default App;