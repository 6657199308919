import React, { useState } from 'react';
import { ethers } from 'ethers';  // Correct import for ethers
import Panel from '../components/styled/Panel';
import { DegenButton, HumanButton, ButtonLabel } from '../components/styled/ButtonElement';
import { useNavigate } from 'react-router-dom';

const ChoiceScreen = () => {
    const navigate = useNavigate();
    const [isHumanExpanded, setIsHumanExpanded] = useState(false);
    const [isDegenExpanded, setIsDegenExpanded] = useState(false);
    const [humanAddress, setHumanAddress] = useState('0xCAE83F10d87C0Ac550A86dA8eB4b786085135447');

    const handleHumanExpand = () => {
        setIsHumanExpanded(true);
    };

    const handleHumanCollapse = () => {
        setIsHumanExpanded(false);
    };

    const handleDegenExpand = () => {
        setIsDegenExpanded(true);
    };

    const handleDegenCollapse = () => {
        setIsDegenExpanded(false);
    };

    const connectToMetaMask = async () => {
        try {
            // Prompt user to connect to MetaMask
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const address = await signer.getAddress();
            console.log(address);
            navigate(`/portfolio/${address}`);
        } catch (error) {
            console.error('Error connecting to MetaMask:', error);
        }
    };

    const handleInputSubmit = (event) => {
        if (event.key === 'Enter') {
            navigate(`/portfolio/${humanAddress}`);
        }
    };

    return (
        <Panel>
            <DegenButton
                onMouseEnter={handleDegenExpand}
                onMouseLeave={handleDegenCollapse}
                onClick={connectToMetaMask}
            >
                {isDegenExpanded ? (
                    <div>
                        <ButtonLabel style={{ marginTop: '-25px', color: '#FFF' }}>Verify My Wallet</ButtonLabel>
                        <button onClick={connectToMetaMask}>Connect to Metamask</button>
                    </div>
                ) : (
                    <ButtonLabel isDegen={true}>Verify My Wallet</ButtonLabel>
                )}
            </DegenButton>
            <HumanButton
                onMouseEnter={handleHumanExpand}
                onMouseLeave={handleHumanCollapse}
            >
                {isHumanExpanded ? (
                    <div>
                        <ButtonLabel style={{ marginTop: '-25px' }}>Search a Wallet</ButtonLabel>
                        <input
                            type="text"
                            placeholder="0xCAE83F10d87C0Ac550A86dA8eB4b786085135447"
                            value={humanAddress}
                            onChange={(e) => setHumanAddress(e.target.value)}
                            onKeyPress={handleInputSubmit}
                        />
                    </div>
                ) : (
                    <ButtonLabel>Search a Wallet</ButtonLabel>
                )}
            </HumanButton>
        </Panel>
    );
};

export default ChoiceScreen;
