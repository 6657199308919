// TransactionDetails.js - Displays the details of each transaction with connecting lines
import React, { useRef, useEffect, useState } from 'react';
import SubTransactionItem from './SubTransactionItem';
import { FlowItem } from './styled/TransactionDetailsStyle';

const TransactionDetails = ({ flows }) => {
  const refs = useRef([]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      {flows.map((flow, index) => (
        <FlowItem key={index} ref={(el) => (refs.current[index] = el)}>
          <SubTransactionItem flow={flow} />
        </FlowItem>
      ))}
    </div>
  );
};

export default TransactionDetails;
