// Portfolio.js - Main page handling filters, sorting, and displaying transactions
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TransactionTimeline from '../components/TransactionTimeline';
import Cards from '../components/Cards';
import Filters from '../components/Filters';
import styled from 'styled-components';
import useFetchTransactions from '../hooks/useFetchTransactions';
import { flattenTransactions, sortTransactions } from '../utils/transactionsUtils';

const backgroundColor = '#0E0E0E'; // Dark background for the page

export const PageContainer = styled.div`
  padding: 20px;
  background-color: ${backgroundColor};
  font-family: 'Anta', sans-serif;
`;

const Portfolio = () => {
  const { address } = useParams();
  const { transactions, groupedTransactions } = useFetchTransactions(address);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [sortCriteria, setSortCriteria] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);

  // Handle filter changes - toggle filters on and off
  const handleFilterChange = (filter) => {
    setActiveFilters((prevFilters) =>
      prevFilters.includes(filter)
        ? prevFilters.filter((f) => f !== filter) // Remove filter if already active
        : [...prevFilters, filter] // Add filter if not active
    );
  };

  // Handle sort changes
  const handleSortChange = (criteria) => {
    setSortCriteria((prevCriteria) => (prevCriteria === criteria ? null : criteria)); // Toggle sort criteria
  };

  // Apply filtering and sorting when criteria change
  useEffect(() => {
    const isSortByType = sortCriteria === 'type';
    let updatedTransactions = isSortByType
      ? flattenTransactions(groupedTransactions)
      : [...groupedTransactions];

    // Apply filter criteria
    if (activeFilters.length) {
      updatedTransactions = updatedTransactions
        .map((group) => {
          const filteredGroup = group.filter((tx) => {
            if (!tx || !tx.timestamp) return false;
            const transactionDate = new Date(tx.timestamp);
            return activeFilters.every((filter) => {
              switch (filter) {
                case 'fiscalYear':
                  const fiscalYearStart = new Date(new Date().getFullYear(), 3, 1);
                  return transactionDate >= fiscalYearStart;
                case 'calendarYear':
                  return transactionDate.getFullYear() === new Date().getFullYear();
                case 'received':
                  return tx.transactionSubtype === 'incoming';
                case 'sent':
                  return tx.transactionSubtype === 'outgoing';
                default:
                  return true;
              }
            });
          });
          return filteredGroup.length ? filteredGroup : null;
        })
        .filter(Boolean);
    }

    // Apply sort criteria
    updatedTransactions = sortTransactions(updatedTransactions, sortCriteria);
    setFilteredTransactions(updatedTransactions);
  }, [activeFilters, sortCriteria, groupedTransactions]);

  return (
    <PageContainer>
      <Cards transactions={transactions} />
      <Filters
        activeFilters={activeFilters}
        activeSort={sortCriteria}
        onFilterChange={handleFilterChange}
        onSortChange={handleSortChange}
      />
      <TransactionTimeline transactions={filteredTransactions} />
    </PageContainer>
  );
};

export default Portfolio;
