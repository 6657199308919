// TransactionTimeline.js - Displays transactions in a timeline format
import React from 'react';
import TransactionDetails from './TransactionDetails';
import { TimelineContainer, TransactionRow, Timestamp, HashContainer, HashLink } from './styled/TransactionTimelineStyle';

const TransactionTimeline = ({ transactions }) => {
  return (
    <TimelineContainer>
      {transactions.map((transaction, index) => (
        <TransactionRow key={index}>
          <Timestamp>{new Date(transaction[0].timestamp).toLocaleString()}</Timestamp>
          <HashContainer>
            <HashLink
              href={`https://etherscan.io/tx/${transaction[0].hash}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {transaction[0].hash.slice(0, 8)}...{transaction[0].hash.slice(-8)}
            </HashLink>
          </HashContainer>
          <TransactionDetails flows={transaction} />
        </TransactionRow>
      ))}
    </TimelineContainer>
  );
};

export default TransactionTimeline;
