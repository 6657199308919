// hooks/useFetchTransactions.js
import { useState, useEffect } from 'react';
import fetchTransactions from '../services/TatumHandler';
import { groupByHash } from '../utils/transactionsUtils';

const useFetchTransactions = (address) => {
  const [transactions, setTransactions] = useState([]);
  const [groupedTransactions, setGroupedTransactions] = useState([]);

  useEffect(() => {
    const fetchAndUpdateTransactions = async () => {
      if (address) {
        try {
          const newTransactions = await fetchTransactions(address);
          if (newTransactions && newTransactions.length > 0) {
            setTransactions((prev) => {
              const allTransactions = [...prev, ...newTransactions];
              const uniqueTransactions = Array.from(
                new Map(allTransactions.map((tx) => [tx.hash, tx])).values()
              );
              return uniqueTransactions;
            });
            const grouped = groupByHash(newTransactions);
            setGroupedTransactions(grouped);
          } else {
            console.warn('No transactions fetched or empty data:', newTransactions);
          }
        } catch (error) {
          console.error('Error fetching transactions:', error);
        }
      }
    };

    fetchAndUpdateTransactions();
  }, [address]);

  return { transactions, groupedTransactions };
};

export default useFetchTransactions;
