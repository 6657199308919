// SubTransactionItem.js - Renders individual sub-transaction details with Type and Direction
import React from 'react';
import { FlowItem } from './styled/TransactionDetailsStyle';


// Helper function to format the direction based on transaction subtype
export const getDirectionLabel = (subtype) => {
  return subtype === 'incoming' ? 'Received' : 'Sent';
};

// Function to format transaction type
export const formatTransactionType = (type) => {
  switch (type) {
    case 'native':
      return 'Native Transfer';
    case 'token':
      return 'Token Transfer';
    case 'contract':
      return 'Contract Interaction';
    default:
      return 'Unknown Type';
  }
};


const SubTransactionItem = ({ flow }) => {
  return (
    <FlowItem type={flow.transactionType} subtype={getDirectionLabel(flow.transactionSubtype)}> {/* Pass type and subtype as props */}
      <span style={{ display: 'block' }}>Type: {formatTransactionType(flow.transactionType)}</span>
      <span style={{ display: 'block' }}>Direction: {getDirectionLabel(flow.transactionSubtype)}</span>
      <span style={{ display: 'block' }}>Counter Address: {flow.counterAddress}</span>
      <span style={{ display: 'block' }}>Amount: {flow.amount}</span>
      <span style={{ display: 'block' }}>Timestamp: {new Date(flow.timestamp).toLocaleString()}</span>
    </FlowItem>
  );
};

export default SubTransactionItem;
