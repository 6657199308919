// Filters.js - Component for filtering and sorting transactions with enhanced neumorphic buttons
import React from 'react';
import styled from 'styled-components';

// Container for filters
const FiltersContainer = styled.div`
  display: flex;
  gap: 10px;
  margin: 20px 0;
  justify-content: center;
  flex-wrap: wrap; /* Ensure it wraps nicely on smaller screens */
`;

// Neumorphic styled button with active state styling
const FilterButton = styled.button`
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  background-color: ${(props) => (props.active ? '#000' : '#f0f0f0')}; /* Active background when selected */
  color: ${(props) => (props.active ? '#fff' : '#000')}; /* Invert text color when active */
  font-weight: bold; /* Bold text */
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
  box-shadow: ${(props) =>
    props.active
      ? 'inset 4px 4px 8px rgba(0, 0, 0, 0.15), inset -4px -4px 8px rgba(255, 255, 255, 0.7);' /* Inner shadow when active */
      : '6px 6px 12px rgba(0, 0, 0, 0.15), -6px -6px 12px rgba(255, 255, 255, 0.7);' /* Standard shadow */};

  &:hover {
    background-color: #000; /* Invert background to black on hover */
    color: #fff; /* Invert text to white on hover */
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15), -4px -4px 8px rgba(255, 255, 255, 0.7); /* Softer shadow on hover */
  }
`;

const Filters = ({ activeFilters, activeSort, onFilterChange, onSortChange }) => {
  // Filter options
  const filters = [
    { label: 'Current Fiscal Year', value: 'fiscalYear' },
    { label: 'Current Calendar Year', value: 'calendarYear' },
    { label: 'Only Received Transactions', value: 'received' },
    { label: 'Only Sent Transactions', value: 'sent' },
  ];

  // Sort options
  const sortOptions = [
    { label: 'Sort by Ascending Time', value: 'ascTime' },
    { label: 'Sort by Counter Address', value: 'counterAddress' },
    { label: 'Sort by Type', value: 'type' },
  ];

  return (
    <FiltersContainer>
      {filters.map((filter) => (
        <FilterButton
          key={filter.value}
          active={activeFilters.includes(filter.value)} // Check if the filter is active
          onClick={() => onFilterChange(filter.value)} // Toggle filter
        >
          {filter.label}
        </FilterButton>
      ))}
      {sortOptions.map((sort) => (
        <FilterButton
          key={sort.value}
          active={activeSort === sort.value} // Check if the sort is active
          onClick={() => onSortChange(sort.value)} // Set the sort criteria
        >
          {sort.label}
        </FilterButton>
      ))}
    </FiltersContainer>
  );
};

export default Filters;
