// Cards.js - Displays summary cards with transaction statistics
import React from 'react';
import {
  calculateTotalTransactions,
  calculateTransactionsThisYear,
  findSeedingWallet,
  calculateTotalInteractedAddresses,
  calculateWalletMostInteractedWith,
  calculateTotalIncomingTransactions
} from '../utils/transactionsUtils';
import { Card, CardTitle, CardValue, CardContainer } from './styled/Cards';

// Helper function to format long strings (wallet addresses)
const formatString = (str) => str ? `${str.slice(0, 5)}...${str.slice(-5)}` : 'N/A';

function Cards({ transactions }) {
  // Calculate transaction stats
  const totalTransactions = calculateTotalTransactions(transactions);
  const totalTransactionsThisYear = calculateTransactionsThisYear(transactions);
  const seedingWallet = findSeedingWallet(transactions);
  const totalInteractedAddresses = calculateTotalInteractedAddresses(transactions);
  const walletMostInteractedWith = calculateWalletMostInteractedWith(transactions);
  const totalIncomingTransactions = calculateTotalIncomingTransactions(transactions);

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
      <CardContainer>
        <Card>
          <CardTitle>Total Transactions</CardTitle>
          <CardValue>{totalTransactions}</CardValue>
        </Card>
        <Card>
          <CardTitle>Transactions This Year</CardTitle>
          <CardValue>{totalTransactionsThisYear}</CardValue>
        </Card>
        <Card>
          <CardTitle>Seeding Wallet</CardTitle>
          <CardValue>
            <a href={`https://etherscan.io/address/${seedingWallet}`} target="_blank" rel="noopener noreferrer">
              {formatString(seedingWallet)}
            </a>
          </CardValue>
        </Card>
        <Card>
          <CardTitle>Total Addresses Interacted With</CardTitle>
          <CardValue>{totalInteractedAddresses}</CardValue>
        </Card>
        <Card>
          <CardTitle>Wallet Most Interacted With</CardTitle>
          <CardValue>
            <a href={`https://etherscan.io/address/${walletMostInteractedWith}`} target="_blank" rel="noopener noreferrer">
              {formatString(walletMostInteractedWith)}
            </a>
          </CardValue>
        </Card>
        <Card>
          <CardTitle>Total Incoming Transactions</CardTitle>
          <CardValue>{totalIncomingTransactions}</CardValue>
        </Card>
      </CardContainer>
    </div>
  );
}

export default Cards;
