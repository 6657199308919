// utils/transactionUtils.js

export const calculateTotalTransactions = (transactions) => transactions.length;

export const calculateTransactionsThisYear = (transactions) => {
  const currentYear = new Date().getFullYear();
  return transactions.filter((tx) => new Date(tx.timestamp).getFullYear() === currentYear).length;
};

export const findSeedingWallet = (transactions) => {
  const sortedTransactions = transactions.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
  return sortedTransactions.length > 0 ? sortedTransactions[0].counterAddress : 'Unknown';
};

export const calculateTotalInteractedAddresses = (transactions) => {
  const addresses = new Set(transactions.map((tx) => tx.counterAddress).concat(transactions.map((tx) => tx.address)));
  return addresses.size;
};

export const calculateWalletMostInteractedWith = (transactions) => {
  const interactionMap = new Map();
  transactions.forEach((tx) => {
    interactionMap.set(tx.counterAddress, (interactionMap.get(tx.counterAddress) || 0) + 1);
  });
  let mostInteracted = { address: '', interactions: 0 };
  interactionMap.forEach((interactions, address) => {
    if (interactions > mostInteracted.interactions) {
      mostInteracted = { address, interactions };
    }
  });
  return mostInteracted.address;
};

export const calculateTotalIncomingTransactions = (transactions) => {
  return transactions.filter((tx) => tx.transactionSubtype === "incoming").length;
};

// Group transactions by hash
export const groupByHash = (transactions) => {
  const grouped = {};
  transactions.forEach((tx) => {
    if (tx && tx.hash) { // Ensure transaction and hash are defined
      if (!grouped[tx.hash]) {
        grouped[tx.hash] = [];
      }
      grouped[tx.hash].push(tx);
    }
  });
  return Object.values(grouped);
};

// Flatten grouped transactions
export const flattenTransactions = (groupedTransactions) => {
  return groupedTransactions.flatMap((group) => group).filter((tx) => tx !== undefined); // Filter out undefined
};

// Sort transactions based on criteria
export const sortTransactions = (transactions, criteria) => {
  if (!criteria) return transactions;

  return transactions.sort((a, b) => {
    const txA = Array.isArray(a) ? a[0] : a;
    const txB = Array.isArray(b) ? b[0] : b;

    // Ensure transactions are valid
    if (!txA || !txB || !txA.timestamp || !txB.timestamp) return 0;

    switch (criteria) {
      case 'ascTime':
        return txA.timestamp - txB.timestamp;
      case 'counterAddress':
        return (txA.counterAddress || '').localeCompare(txB.counterAddress || '');
      case 'type':
        if (txA.transactionType === 'unknown') return 1;
        if (txB.transactionType === 'unknown') return -1;
        return (txA.transactionType || '').localeCompare(txB.transactionType || '');
      default:
        return 0;
    }
  });
};
