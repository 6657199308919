import styled from 'styled-components';

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); // Three columns of equal size
  grid-gap: 20px; // Space between cards
  max-width: 80%; // The same max-width as the table
  margin: 0 auto; // Center the container
  padding: 20px 0; // Add some vertical padding
`;

export const Card = styled.div`
  background: #FFFFFF; // White background for the card
  padding: 20px;
  border-radius: 15px;
  box-shadow: 
    7px 7px 14px rgba(0, 0, 0, 0.25),
    -7px -7px 14px rgba(255, 255, 255, 0.6),
    inset 1px 1px 2px rgba(0, 0, 0, 0.25),
    inset -1px -1px 2px rgba(255, 255, 255, 0.6);
  text-align: center;
  color: #000; // Black text color
  font-family: 'Roboto', sans-serif; // Setting the font
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 
      4px 4px 8px rgba(0, 0, 0, 0.2),
      -4px -4px 8px rgba(255, 255, 255, 1);
  }
`;

export const CardTitle = styled.div`
  font-size: 1em; // Adjust font size as needed
  margin-bottom: 10px; // Adjust bottom margin as needed
  color: #333;
`;

export const CardValue = styled.div`
  font-size: 1.5em; // Adjust font size as needed
  font-weight: bold;
  color: #000;
  a {
    color: #000; // Links are black by default
    text-decoration: underline; // Underline the links by default
    &:hover {
      color: #bbdefb; // Very light blue on hover
      text-decoration: none; // Optionally remove the underline on hover
    }
  }
`;
